.sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .scrollable-menu {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .sticky-menu-group {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
  }